import {
  AppBar,
  Toolbar,
  Typography,
  Paper,
  Container,
} from "@material-ui/core";
import React from "react";
import { BASE_URL } from "../constants";

export default function Index() {
  const [jsonResp, setJsonResp] = React.useState({});

  React.useEffect(() => {
    fetch(`${BASE_URL}/`)
      .then(response => response.json())
      .then(data => {
        setJsonResp(data);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">TeamShout</Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <Paper elevation={3} style={{ marginTop: 50, padding: 20 }}>
          <Typography variant="h5" style={{ marginTop: 30 }}>
            Test API ({BASE_URL})
          </Typography>
          <Typography variant="body1" style={{ marginTop: 20 }}>
            {JSON.stringify(jsonResp, null, 2)}
          </Typography>
        </Paper>
      </Container>
    </>
  );
}
