import {
  AppBar,
  Toolbar,
  Typography,
  Paper,
  Container,
} from "@material-ui/core";
import React from "react";

export default function Index() {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">TeamShout</Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <Paper elevation={3} style={{ marginTop: 50, padding: 20 }}>
          <Typography variant="h2" style={{ marginTop: 30 }}>
            Support
          </Typography>
          <Typography variant="h5" style={{ marginTop: 20 }}>
            Vous avez un problème ?
          </Typography>
          <Typography variant="body1" style={{ marginVertical: 30 }}>
            Envoyez-nous un e-mail et nous nous engageons à vous répondre dans
            les plus brefs délais. L'adresse mail du support :{" "}
            <a href="mailto:jean.b@teamshout.com">jean.b@teamshout.com</a>
          </Typography>
          <Typography variant="h5" style={{ marginTop: 20 }}>
            Edition du site
          </Typography>
          <Typography variant="body1">
            Le site www.TeamShout.com est édité par la société RedFlag SAS
            présidée par Pierre Béal, société par actions simplifiée au capital
            de 3 000 euros, dont le siège social est situé 8, Impasse de la
            Commanderie, 63200 Yssac-La-Tourette, immatriculée au registre du
            commerce et des sociétés sous le numéro d’identification unique 885
            396 812 RCS Clermont-Ferrand.
          </Typography>
          <Typography variant="h5" style={{ marginTop: 20 }}>
            Responsable de publication
          </Typography>
          <Typography variant="body1">Jean Becker</Typography>
          <Typography variant="h5" style={{ marginTop: 20 }}>
            Hébergeur
          </Typography>
          <Typography variant="body1">
            Le site www.TeamShout.com est hébergé par la société OVH Adresse: 2,
            Rue Kellermann, 59100 Roubaix Le stockage des données personnelles
            des Utilisateurs est exclusivement réalisé sur les centre de données
            (“clusters”) localisés dans des Etats membres de l’Union Européenne
            de la société OVH
          </Typography>
          <Typography variant="h5" style={{ marginTop: 20 }}>
            CNIL
          </Typography>
          <Typography variant="body1">
            La société RedFlag SAS conservera dans ses systèmes informatiques et
            dans des conditions raisonnables de sécurité une preuve de la
            transaction comprenant le bon de commande et la facture.
            Conformément aux dispositions de la loi 78-17 du 6 janvier 1978
            modifiée, l’Utilisateur dispose d’un droit d’accès, de modification
            et de suppression des informations collectées par RedFlag SAS. Pour
            exercer ce droit, il reviendra à l’Utilisateur d’envoyer un message
            à l’adresse suivante :{" "}
            <a href="mailto:jean.b@teamshout.com">jean.b@teamshout.com</a>
          </Typography>
        </Paper>
      </Container>
    </>
  );
}
