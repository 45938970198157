import { MenuItem, TextField } from "@material-ui/core";
import { TEAMS } from "../constants";

export default function Index(props) {
  return (
    <TextField
      select
      label={props.title}
      value={props.value}
      onChange={v => props.onChange(v.target.value)}
      style={props.style}
    >
      {TEAMS.filter(v => !v.isInvisible).map(team => (
        <MenuItem key={team.num} value={team.num}>
          {team.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
