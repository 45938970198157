export const BASE_URL = process.env.REACT_APP_API_URL;
export const TEAMS = [
  {
    id: "bayonne",
    name: "Bayonne",
    num: 0,
    stadium: "Stade Jean Dauger",
  },
  {
    id: "perpignan",
    name: "Perpignan",
    num: 1,
    stadium: "Stade Aimé-Giral",
  },
  {
    id: "bordeaux",
    name: "Bordeaux Bègles",
    num: 2,
    stadium: "Stade Chaban-Delmas",
  },
  {
    id: "brive",
    name: "Brive",
    num: 3,
    stadium: "Stade Amédée-Domenech",
  },
  {
    id: "castres",
    name: "Castres",
    num: 4,
    stadium: "Stade Pierre-Fabre",
  },
  {
    id: "clermont",
    name: "Clermont",
    num: 5,
    stadium: "Stade Marcel-Michelin",
  },
  {
    id: "larochelle",
    name: "La Rochelle",
    num: 6,
    stadium: "Stade Marcel-Deflandre",
  },
  {
    id: "lyon",
    name: "Lyon",
    num: 7,
    stadium: "Matmut Stadium",
  },
  {
    id: "montpellier",
    name: "Montpellier",
    num: 8,
    stadium: "GGL Stadium",
  },
  {
    id: "pau",
    name: "Pau",
    num: 9,
    stadium: "Stade du Hameau",
  },
  {
    id: "racing",
    name: "Racing 92",
    num: 10,
    stadium: "La Défense Arena",
  },
  {
    id: "sf",
    name: "SF Paris",
    num: 11,
    stadium: "Stade Jean Bouin",
  },
  {
    id: "toulon",
    name: "Toulon",
    num: 12,
    stadium: "Stade Mayol",
  },
  {
    id: "toulouse",
    name: "Toulouse",
    num: 13,
    stadium: "Stade Ernest-Wallon",
  },
  {
    id: "",
    name: "",
    num: 14,
    stadium: "",
    isInvisible: true,
  },
];
