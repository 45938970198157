import {
  AppBar,
  Toolbar,
  Typography,
  Paper,
  FormControl,
  TextField,
  Container,
  Button,
} from "@material-ui/core";
import SelectTeam from "../components/SelectTeam";
import { Link } from "react-router-dom";
import React from "react";
import { BASE_URL } from "../constants";

export default function Index() {
  const [team, setTeam] = React.useState(5);
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");

  React.useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth !== null) {
      window.location.href = "/";
    }
  }, []);

  const onClick = () => {
    let accessToken = "";
    let userInfo = {};
    if (passwordConfirmation !== password) {
      alert("Les mots de passes rentrés sont différents");
      return;
    }
    fetch(`${BASE_URL}/auth/sign-up`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        team,
        sport: 0,
        league: 0,
        season: 0,
      }),
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          return Promise.reject(res.error);
        }
        accessToken = res.access_token;
      })
      .then(() =>
        fetch(`${BASE_URL}/auth/update`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            email,
            password,
            username,
          }),
        })
      )
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          return Promise.reject(res.error);
        }
      })
      .then(() =>
        fetch(`${BASE_URL}/users/me`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
      )
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          return Promise.reject(res.error);
        }
        userInfo = res;
      })
      .then(() =>
        fetch(`${BASE_URL}/responsibilities/admin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            userId: userInfo._id,
          }),
        })
      )
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          return Promise.reject(res.error);
        }
        if (res.type === 0) {
          alert("Compte crée avec succès");
          localStorage.setItem(
            "auth",
            JSON.stringify({
              username: userInfo.username,
              email: userInfo.email,
              access_token: accessToken,
            })
          );
          window.location.href = "/";
        } else {
          return Promise.reject(
            "Erreur lors de la création du compte (accès insufisant)"
          );
        }
      })
      .catch(err => {
        localStorage.removeItem("auth");
        alert(err.message || err);
      });
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Création d'un compte</Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <Paper elevation={3} style={{ marginTop: 50, padding: 20 }}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              label="Username"
              placeholder="Username"
              value={username}
              onChange={e => setUsername(e.target.value)}
              style={{ marginTop: 20 }}
            />
            <TextField
              label="Email"
              placeholder="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              style={{ marginTop: 20 }}
            />
            <TextField
              label="Mot de passe"
              placeholder="Mot de passe"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              style={{ marginTop: 20 }}
            />
            <TextField
              label="Confirmation du mot de passe"
              placeholder="Confirmation du mot de passe"
              type="password"
              value={passwordConfirmation}
              onChange={e => setPasswordConfirmation(e.target.value)}
              style={{ marginTop: 20 }}
            />
            <SelectTeam
              title={"Equipe favorite"}
              value={team}
              onChange={setTeam}
              style={{ marginTop: 30 }}
            />
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            component="span"
            style={{ marginTop: 40, marginBottom: 20 }}
            onClick={onClick}
          >
            Création
          </Button>
          <Link
            to={`/sign-in`}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            <Button
              variant="contained"
              color="secondary"
              component="span"
              style={{ marginTop: 40, marginBottom: 20, marginLeft: 20 }}
            >
              Connexion
            </Button>
          </Link>
        </Paper>
      </Container>
    </>
  );
}
