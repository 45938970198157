import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CreateUpdateFixture from "./pages/CreateUpdateFixture";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import NotFound from "./pages/NotFound";
import ResetPassword from "./pages/ResetPassword";
import TestApi from "./pages/TestApi";
import Support from "./pages/Support";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/fixtures">
          <CreateUpdateFixture />
        </Route>
        <Route path="/sign-in">
          <SignIn />
        </Route>
        <Route path="/sign-up">
          <SignUp />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/support">
          <Support />
        </Route>
        <Route path="/test-api">
          <TestApi />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}
