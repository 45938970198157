import {
  AppBar,
  Toolbar,
  Typography,
  Paper,
  FormControl,
  TextField,
  Container,
  Button,
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../constants";

export default function Index() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const onClick = () => {
    fetch(`${BASE_URL}/auth/sign-in`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          return Promise.reject(res.error);
        }
        alert("Connexion avec succès");
        localStorage.setItem("auth", JSON.stringify(res));
        window.location.href = "/";
      })
      .catch(err => {
        localStorage.removeItem("auth");
        alert(err.message || err);
      });
  };

  React.useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth !== null) {
      window.location.href = "/";
    }
  }, []);

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Connexion à un compte</Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <Paper elevation={3} style={{ marginTop: 50, padding: 20 }}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              label="Email"
              placeholder="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              style={{ marginTop: 20 }}
            />
            <TextField
              label="Mot de passe"
              placeholder="Mot de passe"
              value={password}
              onChange={e => setPassword(e.target.value)}
              type="password"
              style={{ marginTop: 20 }}
            />
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            component="span"
            style={{ marginTop: 40, marginBottom: 20 }}
            onClick={onClick}
          >
            Connexion
          </Button>
          <Link
            to={`/sign-up`}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            <Button
              variant="contained"
              color="secondary"
              component="span"
              style={{ marginTop: 40, marginBottom: 20, marginLeft: 20 }}
            >
              Création
            </Button>
          </Link>
        </Paper>
      </Container>
    </>
  );
}
