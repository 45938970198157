import {
  AppBar,
  Toolbar,
  Typography,
  Paper,
  FormControl,
  TextField,
  Container,
  Button,
} from "@material-ui/core";
import SelectTeam from "../components/SelectTeam";
import React from "react";
import { BASE_URL } from "../constants/";
import { useQuery } from "../hooks";
import { formatDate, timeZoneTransformer } from "../utils/";

export default function Index() {
  const query = useQuery();
  const [day, setDay] = React.useState(query.get("day") ?? "");
  const [awayTeam, setAwayTeam] = React.useState(query.get("awayTeam") ?? 12);
  const [homeTeam, setHomeTeam] = React.useState(query.get("homeTeam") ?? 5);
  const [startFirstHalf, setStartFirstHalf] = React.useState(
    query.get("startFirstHalf") ? formatDate(query.get("startFirstHalf")) : null
  );
  const [endFirstHalf, setEndFirstHalf] = React.useState(
    query.get("endFirstHalf") ? formatDate(query.get("endFirstHalf")) : null
  );
  const [startSecondHalf, setStartSecondHalf] = React.useState(
    query.get("startSecondHalf")
      ? formatDate(query.get("startSecondHalf"))
      : null
  );
  const [endSecondHalf, setEndSecondHalf] = React.useState(
    query.get("endSecondHalf") ? formatDate(query.get("endSecondHalf")) : null
  );

  React.useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (!auth) {
      window.location.href = "/sign-in";
    }
  }, []);

  const onClick = () => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    fetch(`${BASE_URL}/fixtures`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.access_token}`,
      },
      body: JSON.stringify({
        day: parseInt(day),
        awayTeam: parseInt(awayTeam),
        homeTeam: parseInt(homeTeam),
        startFirstHalf:
          timeZoneTransformer(startFirstHalf).fromUtc.toISOString(),
        endFirstHalf: timeZoneTransformer(endFirstHalf).fromUtc.toISOString(),
        startSecondHalf:
          timeZoneTransformer(startSecondHalf).fromUtc.toISOString(),
        endSecondHalf: timeZoneTransformer(endSecondHalf).fromUtc.toISOString(),
        sport: 0,
        league: 0,
        season: 0,
      }),
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          console.log(res);
          return Promise.reject(res.error);
        }
        window.location.href = "/";
      })
      .catch(err => {
        alert(err.message || err);
      });
  };
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Ajout d'une rencontre</Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <Paper elevation={3} style={{ marginTop: 50, padding: 20 }}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              label="Journée"
              placeholder="Journée"
              style={{ marginTop: 20 }}
              value={day}
              onChange={e => setDay(e.target.value)}
            />
            <SelectTeam
              title={"Equipe jouant à domicile"}
              value={homeTeam}
              onChange={setHomeTeam}
              style={{ marginTop: 30 }}
            />
            <SelectTeam
              title={"Equipe jouant à l'exterieur"}
              value={awayTeam}
              onChange={setAwayTeam}
              style={{ marginTop: 30 }}
            />
            <TextField
              label="Début première mi-temps"
              type="datetime-local"
              defaultValue={startFirstHalf}
              onChange={e => setStartFirstHalf(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 1,
              }}
              style={{ marginTop: 30 }}
            />
            <TextField
              label="Fin première mi-temps"
              type="datetime-local"
              defaultValue={endFirstHalf}
              onChange={e => setEndFirstHalf(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 1,
              }}
              style={{ marginTop: 30 }}
            />
            <TextField
              label="Début seconde mi-temps"
              type="datetime-local"
              defaultValue={startSecondHalf}
              onChange={e => setStartSecondHalf(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 1,
              }}
              style={{ marginTop: 30 }}
            />
            <TextField
              label="Fin seconde mi-temps"
              type="datetime-local"
              defaultValue={endSecondHalf}
              onChange={e => setEndSecondHalf(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 1,
              }}
              style={{ marginTop: 30 }}
            />
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            component="span"
            style={{ marginTop: 40, marginBottom: 20 }}
            onClick={onClick}
          >
            Créer
          </Button>
        </Paper>
      </Container>
    </>
  );
}
