import {
  AppBar,
  Toolbar,
  Typography,
  Paper,
  FormControl,
  TextField,
  Container,
  Button,
} from "@material-ui/core";
import React from "react";
import { BASE_URL } from "../constants";

export default function Index() {
  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const [hasBeenModified, setHasBeenModified] = React.useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  const onClick = () => {
    if (passwordConfirmation !== password) {
      alert("Les mots de passes rentrés sont différents");
      return;
    }
    fetch(`${BASE_URL}/auth/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        password,
      }),
    })
      .then(res => res.json())
      .then(res => {
        if (res.error || res.statusCode === 401) {
          return Promise.reject(res.error || res.message || res);
        }
        setHasBeenModified(true);
      })
      .catch(err => {
        alert(err.message || err);
      });
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">TeamShout</Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <Paper elevation={3} style={{ marginTop: 50, padding: 20 }}>
          {token ? (
            <>
              {hasBeenModified ? (
                <>
                  <Typography variant="h5">Succès</Typography>
                  <Typography variant="body1">
                    Votre mot de passe a bien été réinitialisé.
                  </Typography>
                </>
              ) : (
                <>
                  <FormControl style={{ width: "100%" }}>
                    <TextField
                      label="Nouveau mot de passe"
                      placeholder="Nouveau mot de passe"
                      type="password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      style={{ marginTop: 20 }}
                    />
                    <TextField
                      label="Confirmation du nouveau mot de passe"
                      placeholder="Confirmation du nouveau mot de passe"
                      type="password"
                      value={passwordConfirmation}
                      onChange={e => setPasswordConfirmation(e.target.value)}
                      style={{ marginTop: 20 }}
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{ marginTop: 40, marginBottom: 20 }}
                    onClick={onClick}
                  >
                    Réinitialiser son mot de passe
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              <Typography variant="h5">
                Impossible de réinitialiser votre mot de passe
              </Typography>
              <Typography variant="body1">
                Ré-effectuer la demande sur l'application afin de recevoir un
                nouveau mail.
              </Typography>
            </>
          )}
        </Paper>
      </Container>
    </>
  );
}
