import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  AppBar,
  Toolbar,
  Typography,
  Button,
  TableFooter,
} from "@material-ui/core";
import { TEAMS, BASE_URL } from "../constants";
import { timeZoneTransformer, toLangLocaleString } from "../utils";
import { Link } from "react-router-dom";

export default function Index() {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (!auth) {
      window.location.href = "/sign-in";
      return;
    }
    fetch(`${BASE_URL}/fixtures`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.access_token}`,
      },
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          return Promise.reject(res.error);
        }
        setData(res);
      })
      .catch(err => {
        alert(err.message || err);
      });
  }, []);

  const onLogout = () => {
    localStorage.removeItem("auth");
    window.location.href = "/sign-in";
  };

  const onEditItem = row => {
    window.location.href = `/fixtures?day=${row.day}&awayTeam=${
      row.awayTeam
    }&homeTeam=${row.homeTeam}&startFirstHalf=${timeZoneTransformer(
      row.startFirstHalf
    ).fromUtc.toISOString()}&endFirstHalf=${timeZoneTransformer(
      row.endFirstHalf
    ).fromUtc.toISOString()}&startSecondHalf=${timeZoneTransformer(
      row.startSecondHalf
    ).fromUtc.toISOString()}&endSecondHalf=${timeZoneTransformer(
      row.endSecondHalf
    ).fromUtc.toISOString()}`;
  };

  const onDeleteItem = row => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    const initialData = [...data];
    setData(data.filter(v => v._id !== row._id));
    fetch(`${BASE_URL}/fixtures/${row._id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.access_token}`,
      },
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          return Promise.reject(res.error);
        }
      })
      .catch(err => {
        alert(err.message || err);
        setData(initialData);
      });
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Tableau des rencontres
          </Typography>
          <Button color="inherit" onClick={onLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <Paper
          elevation={3}
          style={{ marginTop: 50, padding: 20, marginBottom: 30 }}
        >
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Journée</TableCell>
                  <TableCell>Team à domicile</TableCell>
                  <TableCell>Team à exterieur</TableCell>
                  <TableCell align="right">Début première mi-temps</TableCell>
                  <TableCell align="right">Fin première mi-temps</TableCell>
                  <TableCell align="right">Début seconde mi-temps</TableCell>
                  <TableCell align="right">Fin seconde mi-temps</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow hover key={index}>
                    <TableCell component="th">{row.day}</TableCell>
                    <TableCell component="th" scope="row">
                      {TEAMS.find(v => v.num === row.homeTeam).name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {TEAMS.find(v => v.num === row.awayTeam).name}
                    </TableCell>
                    <TableCell align="right">
                      {toLangLocaleString(
                        timeZoneTransformer(row.startFirstHalf).fromUtc
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {toLangLocaleString(
                        timeZoneTransformer(row.endFirstHalf).fromUtc
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {toLangLocaleString(
                        timeZoneTransformer(row.startSecondHalf).fromUtc
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {toLangLocaleString(
                        timeZoneTransformer(row.endSecondHalf).fromUtc
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Button onClick={() => onEditItem(row)} color="primary">
                        Editer
                      </Button>
                      <Button
                        onClick={() => onDeleteItem(row)}
                        color="secondary"
                      >
                        Supprimer
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <Link
                  to={`/fixtures`}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{ marginTop: 40, marginBottom: 20 }}
                  >
                    Ajouter ou modifier une rencontre
                  </Button>
                </Link>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </>
  );
}
